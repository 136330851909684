export const ServiceType = {
  micro: "Micro-A",
  sme: "SME-A",
  medium: "SME-B",
  large: "SME-B",
  corporation: "SME-C",
};


export enum Role {
  EndUser = "END_USER",
  Agent = "AGENT",
  SuperAdmin = "SUPER_ADMIN",
}

export const enum DocType {
  CompanyDoc = "company-doc",
  DirectorSharingDoc = "director-sharing-doc",
  LOEDOc = "loe-doc",
}

export const enum DocumentType {
  DocAuthLetter = "doc_auth_letter",
  DocCTOS = "doc_ctos",
  DocLOE = "doc_loe",
  DocSSM = "doc_ssm",
  DOCBankStatement = "doc_bank_statement",
  DOCNRIC = "doc_nric",
  DocAuditedReport = "doc_audited_report",
}


export const enum ApplicationStatus {
  APPLIED = 'APPLIED',
  PROCESSING = 'PROCESSING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}
