import { useUserStore } from "../stores/useUserStore";
import axios from "axios";
import { getLocationParams } from "../utils/helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

axios.interceptors.response.use(
  (response) => interceptResponse(response),
  (error) => interceptError(error)
);

export const interceptResponse = (response: any) => {
  return response;
};

export const interceptError = (error: any, cb?: (...args: any) => void) => {
  const errorMessage = error.response.data.message;
  const statusCode = error.response.data.statusCode;

  if (errorMessage === "Unauthorized" && statusCode === 401) {
    return handleTokenExpiryError();
  }

  // Show toast for 400 or 500 errors
  if (statusCode === 400 || statusCode === 500) {
    toast.error(`Error ${statusCode}: ${errorMessage}`, {
      position: 'bottom-left',
    });
  }

  return Promise.reject(error);
};

const handleTokenExpiryError = () => {
  clearUserData();
  window.location.href = "/signin";
};

export const clearUserData = () => {
  localStorage.removeItem("onboarding-step");
  localStorage.removeItem("user-storage");
}

export const requestMethod = {
  get: "get",
  put: "put",
  post: "post",
  delete: "delete",
  patch: "patch",
};

export const requestType = {
  xmlHttpRequest: "xmlHttpRequest",
  authBearerToken: "authBearerToken",
  authBearerTokenNoCache: "authBearerTokenNoCache",
  authBearerTokenMultipartFormData: "authBearerTokenMultipartFormData",
  noAuth: "", // empty authorization header
};

export const sendRequest: any = (
  endpoint = process.env.NEXT_PUBLIC_API_URL,
  reqMethod = requestMethod.get,
  params = {},
  type = requestType.authBearerToken,
  bearerToken = null,
  bypassMetadataThrottle = false
) => {
  const user: any = localStorage.getItem("user-storage");

  const userObj = JSON.parse(user);

  let auth;
  let paramType = "params";
  let headers: Record<string, string | undefined> = {
    "Access-Control-Allow-Origin": "*",
  };

  if (
    reqMethod === requestMethod.post ||
    reqMethod === requestMethod.put ||
    reqMethod === requestMethod.patch
  ) {
    paramType = "data";
  }

  if (type === requestType.xmlHttpRequest) {
    headers = {
      ...headers,
    };
  } else if (
    type === requestType.authBearerToken ||
    type === requestType.authBearerTokenNoCache ||
    type === requestType.authBearerTokenMultipartFormData
  ) {
    bearerToken =
      bearerToken !== null
        ? bearerToken
        : userObj?.state?.accessToken;

    headers = {
      ...headers,
      Accept: "application/json",
      Authorization: `Bearer ${bearerToken}`,
    };

    if (type === requestType.authBearerTokenNoCache) {
      headers = {
        ...headers,
        "Cache-Control": "no-cache, max-age=0, must-revalidate, no-store",
      };
    } else if (type === requestType.authBearerTokenMultipartFormData) {
      headers = {
        ...headers,
        "Content-Type": "multipart/form-data",
      };
    }
  } else if (type === requestType.noAuth) {
    // we created this noAuth (empty authorization header)
    // to skip the basic auth headers for Join/Login pages
    // just leave this blank
  }

  const query = getLocationParams(window.location);
  headers = {
    ...headers,
    ...(query?.accessToken && { "x-access-token": query.accessToken }),
    ...(query?.bypassRequirement && { "x-bypass-requirement": "password" }),
  };

  let axiosOptions = {
    method: reqMethod,
    url: endpoint,
    [paramType]: params,
    headers,
    // withCredentials,
  };

  if (auth) {
    axiosOptions = {
      ...axiosOptions,
      auth,
    };
  }

  return axios(axiosOptions);
};
