export interface ILocation {
  query: { [key: string]: string };
  pathname: string;
  search?: string;
  hash?: string;
  state?: { [key: string]: unknown };
}

export const getLocationParams = (
  location: Pick<ILocation, "search">
): Record<string, string | undefined> =>
  location.search
    ?.slice(1)
    .split("&")
    .map((p) => p.split("="))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent);
      return { ...obj, [key]: value };
    }, {}) || {};

export function removeEmptyValues(obj: Object) {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== null && value !== undefined && value !== ""
    )
  );
}

export const getRoleByUser = (user: any) => {
  if (user?.roles?.includes("SUPER_ADMIN")) {
    return "SUPER_ADMIN";
  }

  if (user?.roles?.includes("AGENT")) {
    return "AGENT";
  }
  return "END_USER";
};

export const getTodayDateNew = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, "0");
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = today.getFullYear();

  const formattedDate = `${day} ${month} ${year}`; // Output: DD MM YYYY

  return formattedDate;
};

export const getTodayDate = () => {
  const today = new Date();

  // Get the day, month, and year
  const day = today.getDate();
  const monthIndex = today.getMonth();
  const year = today.getFullYear();

  // Month names array
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Get the month name
  const monthName = months[monthIndex];

  // Function to get the suffix for the day
  const getDaySuffix = (day: any) => {
    if (day > 3 && day < 21) return "th"; // For 11th to 13th
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  // Format the date
  const formattedDate = `${String(day).padStart(2, "0")}${getDaySuffix(
    day
  )} ${monthName} ${year}`;
  console.log(formattedDate); // Outputs: 08th August 2024
  return formattedDate;
};

export const getCurrentTime = () => {
  const today = new Date();
  const createdAt =
    today.getFullYear() +
    "-" +
    String(today.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(today.getDate()).padStart(2, "0") +
    " " +
    String(today.getHours()).padStart(2, "0") +
    ":" +
    String(today.getMinutes()).padStart(2, "0") +
    ":" +
    String(today.getSeconds()).padStart(2, "0");
  console.log(createdAt); // "2024-09-29 12:45:23" (Example)
  return createdAt;
};

export const getRandomBgColor = (value = "blue") => {
  const colors = [
    "bg-red-500",
    "bg-blue-500",
    "bg-green-500",
    "bg-yellow-500",
    "bg-purple-500",
    "bg-pink-500",
    "bg-indigo-500",
    "bg-orange-500",
    "bg-teal-500",
  ];

  // Use the input value to generate a consistent index
  const index = Math.abs(value) % colors.length; // Ensures the index is within bounds
  return colors[index];
};
