import Link from 'next/link'
import Image from "next/image";

export default function Logo() {
  return (
    <Link className="block" href="/">
      <Image src="/images/odela-logo.png" width="32" height="32" alt="odela logo"/>
    </Link>
  )
}
