import { sendRequest } from "../api/sdk";
import { create } from "zustand";

import { createJSONStorage, persist } from "zustand/middleware";
import { saveItem } from "../utils/localStorage";
import { getRoleByUser } from "../utils/helpers";
import { Role } from "../components/utils/constant";
interface State {
  user: any;
  accessToken?: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: any;
  userInfo: any;
}

export interface Query {
  limit?: number;
  skip?: number;
  price?: [number, number];
  category?: string;
  stars?: number;
  q?: string;
}

interface Actions {
  reset: () => void;
  getProfile: () => Promise<void>;
  register: (payload: any) => Promise<void>;
  login: (payload: any) => Promise<void>;
  forgotPassword: (payload: any) => Promise<string>;
  resetPassword: (payload: any, otpCode: string) => Promise<void>;
  changePassword: (payload: any) => Promise<string>;
  sendEmailVerification: (userId: string) => Promise<string>;
  verifyEmail: (userId: string, token: string) => Promise<boolean>;
  fetchUserById: (id: string) => Promise<void>;
}

const INITIAL_STATE: State = {
  user: null,
  isAuthenticated: false,
  isLoading: false,
  accessToken: "",
  error: null,
  userInfo: null,
};

export const useUserStore = create(
  persist<State & Actions>(
    (set, get) => ({
      user: INITIAL_STATE.user,
      isAuthenticated: false,
      accessToken: INITIAL_STATE.accessToken,
      isLoading: INITIAL_STATE.isLoading,
      error: INITIAL_STATE.error,
      userInfo: INITIAL_STATE.userInfo,
      reset: () => {
        set(INITIAL_STATE);
      },
      getProfile: async () => {
        try {
          set({ isLoading: true, error: null });
          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/profile`;

          const response = await sendRequest(url, "get");
          set({ user: response.data, isLoading: false });
          return response.data;
        } catch (error) {
          console.log("error get Profile", error);
          set({ error, isLoading: false });
        }
      },
      fetchUserById: async (id: string) => {
        try {
          set({ isLoading: true, error: null });
          const url = process.env.NEXT_PUBLIC_API_URL + `/users/${id}`;

          const response = await sendRequest(url, "get");
          set({ userInfo: response.data, isLoading: false });
          return response.data;
        } catch (error) {
          console.log("error get Profile", error);
          set({ error, isLoading: false });
        }
      },
      login: async (payload: any) => {
        try {
          set({ isLoading: true, error: null });
          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/login`;

          const response = await sendRequest(url, "post", payload);
          set({ user: response.data, isLoading: false, accessToken: response.data.accessToken, isAuthenticated: true });

          const role = getRoleByUser(response.data.user);

          if(response?.data?.user?.company?.eligibilityStatus || role === Role.Agent || role === Role.SuperAdmin) {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/onboarding";
          }
        } catch (error) {
          console.log("error login", error);
          set({ error, isLoading: false });
        }
      },
      register: async (payload: any) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/register`;
          const response = await sendRequest(url, "post", payload);
          const data = await response.data;

          //create company
          const onboardingUrl = process.env.NEXT_PUBLIC_API_URL + `/companies`;
          const onboardingData = {
            name: payload.companyName,
          }

          await sendRequest(onboardingUrl, "post", onboardingData, 'authBearerToken', data.accessToken);
          
          set({ user: data, isLoading: false, accessToken: data.accessToken, isAuthenticated: true });

          window.location.href = "/onboarding";
        } catch (error) {
          // console.log("error register", error);
          set({ error, isLoading: false });
        }
      },
      forgotPassword: async (payload: any) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/forgot-password`;
          const response = await sendRequest(url, "post", payload);
          const data = await response.data;

          set({ isLoading: false, error: null });

          return data.message;
        } catch (error) {
          set({ error, isLoading: false });
          return error as string;
        }
      },
      resetPassword: async (payload: any, otpCode: string) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/reset-password/` + otpCode;
          const response = await sendRequest(url, "post", payload);
          const data = await response.data;

          set({ isLoading: false, error: null });

          window.location.href = "/signin";
        } catch (error) {
          set({ error, isLoading: false });
        }
      },
      changePassword: async (payload: any) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/auth/change-password`;
          const response = await sendRequest(url, "post", payload);
          const data = await response.data;

          set({ isLoading: false, error: null });

          return data.message;
        } catch (error) {
          set({ error, isLoading: false });
          return '';
        }
      },
      sendEmailVerification: async (userId: string) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/users/${userId}/send-mail-verification`;
          const response = await sendRequest(url, "post", {});
          const data = await response.data;

          set({ isLoading: false, error: null });

          return data.message;
        } catch (error) {
          set({ error, isLoading: false });
          return error as string;
        }
      },
      verifyEmail: async (userId: string, token: string) => {
        try {
          set({ isLoading: true, error: null });

          const url = process.env.NEXT_PUBLIC_API_URL + `/users/${userId}/${token}`;
          const response = await sendRequest(url, "post", {});
          const data = await response.data;

          set({ isLoading: false, error: null });

          return data.verified;
        } catch (error) {
          set({ error, isLoading: false });
          return false;
        }
      }

    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
